import { ResponseCode } from './ResponseCode';
import { DeviceId, NoDeviceId } from './DeviceId';
import MsgResponseBase from './MsgResponseBase';

export default class MsgGetDeviceIdResponse extends MsgResponseBase {
	/**
	 * The assigned device id.
	 *
	 * @TJS-type string
	 */
	public readonly deviceId: DeviceId;

	public readonly otherDeviceIds: DeviceId[];
	public readonly otherDevicePlayerNames: string[];
	public readonly otherDevicesDisconnected: boolean[];

	public static buildSuccess(
		msgId: number,
		deviceId: DeviceId,
		otherDeviceIds: DeviceId[],
		otherDevicePlayerNames: string[],
		otherDevicesDisconnected: boolean[]
	) {
		return new MsgGetDeviceIdResponse(
			msgId,
			ResponseCode.Success,
			deviceId,
			otherDeviceIds,
			otherDevicePlayerNames,
			otherDevicesDisconnected
		);
	}

	public static buildError(msgId: number, responseCode: ResponseCode, errorDetails: string) {
		return new MsgGetDeviceIdResponse(msgId, responseCode, NoDeviceId, [], [], [], errorDetails);
	}

	private constructor(
		msgId: number,
		responseCode: ResponseCode,
		deviceId: DeviceId,
		otherDeviceIds: DeviceId[],
		otherDevicePlayerNames: string[],
		otherDevicesDisconnected: boolean[],
		errorDetails?: string
	) {
		super(msgId, responseCode, errorDetails);
		this.deviceId = deviceId;
		this.otherDeviceIds = otherDeviceIds;
		this.otherDevicePlayerNames = otherDevicePlayerNames;
		this.otherDevicesDisconnected = otherDevicesDisconnected;
	}

	protected toStringExtra = (): string => {
		return `deviceId:${this.deviceId}`;
	};
}
