/**
 * Constants for data in MsgMessages received on GameProxy's connection
 */
export abstract class GameMessageData {
	static SetState: string = 'setState';

	static ConfirmCharacters = class {
		static Name: string = 'meta.game.confirmCharacters';
		static AllNonCharactersSelected: string = 'allNonCharactersSelected';
	};
	static ThrowEnabled: string = 'meta.game.throwEnabled';

	static NumTurns: string = 'meta.game.numTurns';

	static BootController: string = 'bootController';

	static CustomController = class {
		static ActionButton: string = 'customController.actionButton';
	};

	static ImageBase64 = class {
		static ImageName: string = 'imageBase64.imageName';
		static Data: string = 'imageBase64.data';
	};

	static ItemManagement = class {
		static Name: string = 'itemManagementData';
		static Positions: string = 'placementPositions';
		static ItemNames: string = 'itemNames';
	};

	static NarrativeCharacters = class {
		static Name: string = 'narrativeCharactersData';
		static CharacterInternalNames: string = 'characterInternalNames';
		static CharacterDisplayNames: string = 'characterDisplayNames';
	};
}
