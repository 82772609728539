import MsgBase from './MsgBase';
import IMessage, { isIMessage } from './IMessage';
import MsgResponseBase from './MsgResponseBase';
import { LogFactory } from '../Log';
import MsgClientSentBase from './MsgClientSentBase';
import MsgAddControllerResponse from './MsgAddControllerResponse';
import { ResponseCode } from './ResponseCode';
import { ConnectionId } from './ConnectionId';
import { PostToConnection } from 'aws-lambda-ws-server';
import { JoinCode, NoJoinCode } from './JoinCode';
import { IMessageRunContext } from '../../server/IRoutingServer';
import DeviceRecord from '../../server/model/DeviceRecord';
import assert from 'assert';
import DatabaseUtils from '../../server/model/database/DatabaseUtils';
import { Game } from '../../server/model/Game';
import { RecordType } from '../../server/model/database/GamesAndDevicesTypes';
import MsgInfoFromServerControllerConnected from './MsgInfoFromServerControllerConnected';
import { CommandValue } from './Command';

export const NoPlayerName = 'NO_PLAYER_NAME';

export interface IMsgAddController extends IMessage {
	/**
	 * Code to join the game.
	 *
	 * @minimum 0
	 * @maximum 9999
	 * @TJS-type integer
	 */
	readonly joinCode: JoinCode;

	/**
	 * Player name.
	 * @TSJ-type string
	 */
	readonly playerName: string;
}

export default class MsgAddController extends MsgClientSentBase<MsgAddControllerResponse> implements IMsgAddController {
	public readonly command = CommandValue.AddController;

	/**
	 * Code to join the game.
	 * @TJS-type string
	 */
	public readonly joinCode: JoinCode;

	/**
	 * Player name.
	 * @TSJ-type string
	 */
	readonly playerName: string;

	public constructor(joinCode: JoinCode, playerName: string, msgId: number = MsgBase.MsgIdAuto) {
		super(msgId);
		this.joinCode = joinCode;
		// Validator now rejects playerName containing 'bad words'.
		// const Filter = require('bad-words');
		// const filter = new Filter();
		// this.playerName = filter.clean(playerName);
		this.playerName = playerName;
	}

	public static isMsgAddController(o: any): o is MsgAddController {
		return undefined !== o.joinCode && isIMessage(o) && CommandValue.AddController === o.command;
	}

	public static buildFromIMessage(o: IMessage): MsgAddController {
		const withMethods = new MsgAddController(NoJoinCode, NoPlayerName, this.MsgIdInvalidToBeOverwritten);
		Object.assign(withMethods, o); // copy `o` over withMethods
		return withMethods;
	}

	/**
	 * Add a controller to a game.
	 * (1) Update the game with the device,
	 * (2) Update device with the game,
	 * (3) Inform the game and
	 * (4) Inform the controller.
	 */
	public run = async (
		connectionId: ConnectionId,
		context: IMessageRunContext,
		postToConnection: PostToConnection
	): Promise<MsgResponseBase> => {
		const backingStore = context.backingStore;
		const device = (await backingStore.getDeviceByConnectionId(connectionId))!; // throws if does not exist
		Log.debug(
			`[${this.msgId}] adding connectionId:${connectionId} = device:${device} to game:${this.joinCode} with playerName:"${this.playerName}"`
		);

		assert(undefined !== device.connectionId, `addControllerToGame() found no connectionId in ${device}`);
		// Before proceeding to update the Game, validate that it is connected.
		// (we do not allow even temporarily disconnected games to be joined)
		// That said, this only checks we _think_ it is connected -- not that it is actually connected.
		Log.debug('1. Update the game with the device (non-blocking loop attempting to update Game in DB)');
		if (!(await context.operations.gameIsConnected(this))) {
			return MsgAddControllerResponse.buildError(
				this.msgId,
				ResponseCode.ConnectionUnavailable,
				'Game is presently disconnected (temporarily?)'
			);
		}

		Log.debug('2. Update the game with the device (non-blocking loop attempting to update Game in DB)');
		const game = await DatabaseUtils.nonBlockingUpdate<Game>(
			// Get
			async (): Promise<Game> => {
				let game: Game | undefined;
				try {
					game = await backingStore.getGameByJoinCode(this.joinCode);
				} catch {
					/* nop = fall-through */
				}
				if (game) return game;

				const errMsg = `Invalid join code "${this.joinCode}" from deviceId:${device}`;
				Log.error(errMsg);
				throw MsgAddControllerResponse.buildError(this.msgId, ResponseCode.JoinCodeInvalid, errMsg);
			},

			// Update
			(game): void => {
				if (!game.addController(device.id, device.connectionId!)) {
					const errMsg = `Device "${device}" failed to join game "${this.joinCode}"`;
					Log.error(errMsg);
					throw MsgAddControllerResponse.buildError(this.msgId, ResponseCode.JoinCodeNotAllowed, errMsg);
				}
			},

			// Put
			(game) => backingStore.updateGame(game)
		);

		Log.debug('3. Update device record');
		await DatabaseUtils.nonBlockingUpdate<DeviceRecord>(
			async () => (await backingStore.getDeviceByDeviceId(device.id))!,
			(device) => {
				Log.silly('Recording that device', device, 'is a Controller connected to game', game);
				device.game = game.joinCode;
				device.type = RecordType.ControllerDevice;
			},
			(device) => backingStore.updateDevice(device)
		);
		// Store player name for device in case needed by game when reconnecting
		context.operations.setPlayerNameForDeviceId(device.id, this.playerName);

		Log.debug('4. Inform the game');
		const result = await context.outbound.sendToDeviceIds(
			[game.gameDeviceId],
			new MsgInfoFromServerControllerConnected(device.id, this.playerName, false),
			postToConnection
		);

		if (ResponseCode.Success === result.responseCode) {
			Log.debug('5. Inform the controller');
			return MsgAddControllerResponse.buildSuccess(this.msgId);
		}

		Log.error(`Failed to inform game of controller connected: ${result}`);

		// The rest of this is undoing the database changes.

		Log.debug('4e1. Undo Update device record');
		await DatabaseUtils.nonBlockingUpdate<DeviceRecord>(
			async () => (await backingStore.getDeviceByDeviceId(device.id))!,
			(device) => {
				Log.silly('Recording that device', device, 'is a Controller NOT connected to game', game);
				device.game = ''; // blank TODO: Is this the correct way to clear a field?  Perhaps delete field?
				device.type = RecordType.ControllerDevice;
			},
			(device) => backingStore.updateDevice(device)
		);

		Log.debug('4e2. Undo Update Game record');
		await DatabaseUtils.nonBlockingUpdate<Game>(
			// Get
			async (): Promise<Game> => {
				let game: Game | undefined;
				try {
					game = await backingStore.getGameByJoinCode(this.joinCode);
				} catch {
					/* nop = fall-through */
				}
				if (game) return game;

				const errMsg = `Invalid join code "${this.joinCode}" from deviceId:${device}`;
				Log.error(errMsg);
				throw MsgAddControllerResponse.buildError(this.msgId, ResponseCode.JoinCodeInvalid, errMsg);
			},

			// Update
			(game): void => {
				if (!game.removeController(device.id, device.connectionId!)) {
					const errMsg = `Device "${device}" failed to UN-join game "${this.joinCode}"`;
					Log.error(errMsg);
					throw MsgAddControllerResponse.buildError(this.msgId, ResponseCode.JoinCodeNotAllowed, errMsg);
				}
			},

			// Put
			(game) => backingStore.updateGame(game)
		);

		return MsgAddControllerResponse.buildError(this.msgId, result.responseCode, result.errorDetails!);
	};

	public buildErrorResponse(responseCode: ResponseCode, errorDetails: string): MsgAddControllerResponse {
		return MsgAddControllerResponse.buildError(this.msgId, responseCode, errorDetails);
	}

	toString = (): string =>
		`${this.constructor.name}(msgId:${this.msgId}, command:${this.command}, joinCode:${this.joinCode}, playerName:"${this.playerName}")`;
}

const Log = LogFactory.build(MsgAddController.name);
