import MsgBase from './MsgBase';
import IMessage, { isIMessage } from './IMessage';
import MsgResponseBase from './MsgResponseBase';
import Log from '../Log';
import MsgClientSentBase from './MsgClientSentBase';
import MsgGetDeviceIdResponse from './MsgGetDeviceIdResponse';
import { ResponseCode } from './ResponseCode';
import { DeviceId } from './DeviceId';
import { ConnectionId } from './ConnectionId';
import { PostToConnection } from 'aws-lambda-ws-server';
import { IMessageRunContext } from '../../server/IRoutingServer';
import { CommandValue } from './Command';

export interface IMsgGetDeviceId extends IMessage {}

export default class MsgGetDeviceId extends MsgClientSentBase<MsgGetDeviceIdResponse> implements IMsgGetDeviceId {
	public readonly command = CommandValue.GetDeviceId;

	public constructor(public readonly desiredDeviceId?: DeviceId, msgId: number = MsgBase.MsgIdAuto) {
		super(msgId);
	}

	public static isMsgGetDeviceId(o: any): o is MsgGetDeviceId {
		return isIMessage(o) && CommandValue.GetDeviceId === o.command;
	}

	public static buildFromIMessage(o: IMessage): MsgGetDeviceId {
		const withMethods = new MsgGetDeviceId(undefined, this.MsgIdInvalidToBeOverwritten);
		Object.assign(withMethods, o); // copy `o` over withMethods
		return withMethods;
	}

	public run = async (
		connectionId: ConnectionId,
		context: IMessageRunContext,
		postToConnection: PostToConnection
	): Promise<MsgResponseBase> => {
		try {
			const deviceId = await context.operations.getOrAddDeviceForConnectionId(
				connectionId,
				postToConnection,
				this.desiredDeviceId
			);

			// Also include info about all other devices in response
			//	(deviceIds, player names and whether temp. disconnected),
			//	which the game client can use to update controller records if needed.
			let otherDeviceIds: DeviceId[] = [];
			let otherDevicePlayerNames: string[] = [];
			let otherDevicesDisconnected: boolean[] = [];
			let game = await context.backingStore.getGameByJoinCode(deviceId.game ?? '', false);
			if (game) {
				for (const controllerDeviceId of game.controllerDeviceIds) {
					if (controllerDeviceId === deviceId.id) {
						continue;
					}
					otherDeviceIds.push(controllerDeviceId);
					let playerName = context.operations.getPlayerNameForDeviceId(controllerDeviceId);
					otherDevicePlayerNames.push(playerName);
					let device = await context.backingStore.getDeviceByDeviceId(controllerDeviceId);
					otherDevicesDisconnected.push(device?.disconnected ?? true);
				}
			}

			Log.debug(`[${this.msgId}] ConnectionId:${connectionId} = deviceId:${deviceId}`);
			return MsgGetDeviceIdResponse.buildSuccess(
				this.msgId,
				deviceId.id,
				otherDeviceIds,
				otherDevicePlayerNames,
				otherDevicesDisconnected
			);
		} catch (e: unknown) {
			let errorString: string;
			switch (typeof e) {
				case 'string':
					errorString = e;
					break;

				// See WebSocketServer.onConnection
				// case 'object':
				// 	const o:object = <object> e;
				//	if ('responseCode' in o || o instanceof ErrorWithCode) { ... } else ... ?
				// 	errorString = JSON.stringify(o);
				// 	break;

				default:
					errorString = JSON.stringify(e);
					break;
			}
			return MsgGetDeviceIdResponse.buildError(this.msgId, ResponseCode.Failure, errorString); // TODO: Leaks secret data!
		}
	};

	public buildSuccessResponse(
		deviceId: DeviceId,
		otherDeviceIds: DeviceId[],
		otherDevicePlayerNames: string[],
		otherDevicesDisconnected: boolean[]
	): MsgGetDeviceIdResponse {
		return MsgGetDeviceIdResponse.buildSuccess(
			this.msgId,
			deviceId,
			otherDeviceIds,
			otherDevicePlayerNames,
			otherDevicesDisconnected
		);
	}

	public buildErrorResponse(responseCode: ResponseCode, errorDetails: string): MsgGetDeviceIdResponse {
		return MsgGetDeviceIdResponse.buildError(this.msgId, responseCode, errorDetails);
	}

	toString = (): string => `${this.constructor.name}(msgId:${this.msgId}, command:${this.command})`;
}
